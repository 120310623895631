@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Text');

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 1em;
}

a {
  text-decoration: none;
  color: inherit;
}

.header-right {
  justify-content: flex-end;
  display: flex;
}

header a {
  margin-left: 2rem;
  transition: color 0.2s;
}

.header-left>a {
  margin-left: 0;
  margin-right: 0;
}

.header-right>a:last-child {
  margin-right: 2rem;
}

@media (hover: hover) {

  .header-right>a::after {
    display: block;
    content: '';
    border-bottom: 2px solid rgb(70, 170, 200);
    margin-bottom: -2px;
    transform: scaleX(0);
    transition: transform 0.2s;
  }

  .header-right>a:hover,
  .header-right>a:active {
    color: rgb(67, 166, 199);
  }

  .header-right>a:hover::after {
    transform: scaleX(1);
  }
}

.App-header {
  user-select: none;
  font-family: 'Big Shoulders Text';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(18pt + 1vmin);
  color: gray;
  padding-left: 1em;
  padding-right: 1em;
}


hr {
  margin-left: 10%;
  margin-right: 10%;
}

.content {
  margin: 2rem 4rem 2rem 4rem;
  flex: 1;
}

footer {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
  
@media screen and (max-width: 600px) {

  .App-header {
    flex-direction: column;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
  }

  .content {
    margin: 2rem;
  }
}