.project-list-item {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    background-color: whitesmoke;
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.project-list-item > div:not(.image-wrapper) {
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-left: 1rem;
}

.project-list-item h3 {
    margin: 0px;
}

.image-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.project-list-item > div > img {
    height: 100%;
    max-width:100%;
    object-fit: contain;
    border-radius: 8px;
    min-width: 240px;
}

.image-wrapper > p {
    font-style: italic;
    color: gray;
}

.link-list {
    margin-top: auto;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

@media screen and (max-width: 600px) {
    .project-list-item {
        flex-direction: column;
    }

    .project-list-item > div:not(.image-wrapper) {
        margin-left: 0.5rem;
    }

    .image-wrapper {
        margin-bottom: 1rem;
    }

    .image-wrapper > p {
        display: none;
    }
}

@media screen and (max-width: 360px){
    .project-list-item > div > img {
        min-width: 0px;
    }
}