ul {
    padding-left: 0;
}

li {
    overflow: hidden;
    list-style-type: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-left: 0;
}
